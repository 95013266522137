import { Dialog, DialogContent } from '@mui/material';
import React, { ReactNode } from 'react';
import CreateInvitationsForm, { FormValues } from '@components/dashboard/CreateInvitationsForm';
import useApi from '@hooks/useApi';
import { Invite, InviteCreateParams, InviteExistingUser } from '@models/Invitation';
import Endpoints from '@services/Endpoints';
import { SharingMember } from '@models/User';
import { Alert } from '@mui/material';
import EditSharingMembersList, { EditMembersProps } from '@components/sharing/EditSharingMembersList';
import Logger from '@util/Logger';
import DialogCloseButton from '@components/DialogCloseButton';
import PlutoDialogTitle from '@components/PlutoDialogTitle';
import InviteExistingMemberForm from '../dashboard/InviteExistingMemberForm';

const logger = Logger.make('SharingDialog');

type DialogProps = EditMembersProps & {
    title?: ReactNode;
    open: boolean;
    onClose: () => void;
    members?: SharingMember[];
    membersLoading?: boolean;
    membersCount?: number;
    onInvitesSent?: (invites: Invite[]) => void | Promise<void>;
    showDescription?: boolean;
    error?: string | ReactNode | null;
    hideMembers?: boolean;
    hideSubTitle?: boolean;
    canTransferOwnership?: boolean;
    onOwnershipChanged?: () => Promise<void>;
    availableMembers?: SharingMember[];
    handleInviteExistingUsers?: (values: InviteExistingUser) => Promise<{ success: boolean; error: string }>;
    inviteSuccess?: boolean;
};

const SharingDialog = ({
    item,
    itemType,
    title,
    open,
    onClose,
    roleLevels,
    members,
    membersCount,
    removeMember,
    canEditRoles,
    canRemoveMembers,
    error,
    saveMember,
    hideMembers = false,
    hideSubTitle = false,
    onInvitesSent,
    canTransferOwnership = false,
    onOwnershipChanged,
    availableMembers,
    handleInviteExistingUsers,
}: DialogProps) => {
    const api = useApi();
    const closeModal = onClose;

    const submitInvites = async (invites: InviteCreateParams[]) => {
        const success: Invite[] = [];
        const errors: InviteCreateParams[] = [];

        const inviteResponses = await Promise.all(
            invites.map(async (invite) => {
                try {
                    const created = await api.fetcher<Invite>(Endpoints.user.invites(), {
                        method: 'POST',
                        body: JSON.stringify(invite),
                    });
                    success.push(created);
                } catch (error) {
                    logger.error(error);
                    errors.push(invite);
                }
            }),
        );

        logger.info('inviteResponses', inviteResponses);
        if (success.length > 0) {
            onInvitesSent?.(success);
        }

        return { success, errors };
    };

    const handleSubmit = async (values: FormValues) => {
        const { success, errors } = await submitInvites(
            values.users.map((user) => {
                return {
                    to_email: user.email,
                    role_type: user.role_type,
                    invite_type: itemType,
                    invite_type_id: item.uuid,
                };
            }),
        );
        logger.info('save success', { success, errors });
        return { success, errors };
    };

    const inviteExistingUsers = async (values: InviteExistingUser) => {
        if (!handleInviteExistingUsers) return { success: false, error: '' };
        const { success, error } = await handleInviteExistingUsers(values);
        return { success, error };
    };

    return (
        <Dialog open={open} onClose={closeModal} fullWidth maxWidth="sm">
            <DialogCloseButton onClose={() => closeModal()} />

            <PlutoDialogTitle
                title={title}
                subTitle={
                    !hideSubTitle &&
                    item.name && (
                        <p className="text-base text-default">
                            {!!availableMembers ? 'Add' : 'Invite'} members to{' '}
                            <span className="font-semibold">{item.name}</span>
                        </p>
                    )
                }
            />

            <DialogContent className="space-y-8">
                <div className="">
                    {error && <Alert severity="error">{error}</Alert>}
                    {!!availableMembers ? (
                        <InviteExistingMemberForm
                            onSubmit={inviteExistingUsers}
                            roleLevels={roleLevels}
                            itemType={itemType}
                            availableMembers={availableMembers}
                            closeModal={closeModal}
                        />
                    ) : (
                        <CreateInvitationsForm
                            onSubmit={handleSubmit}
                            roleLevels={roleLevels}
                            itemType={itemType}
                            closeModal={closeModal}
                        />
                    )}
                </div>

                {!hideMembers && (membersCount ?? 0) > 0 && (
                    <div>
                        <EditSharingMembersList
                            itemType={itemType}
                            membersCount={membersCount}
                            members={members}
                            removeMember={removeMember}
                            item={item}
                            canEditRoles={canEditRoles}
                            canRemoveMembers={canRemoveMembers}
                            roleLevels={roleLevels}
                            saveMember={saveMember}
                            canTransferOwnership={canTransferOwnership}
                            onOwnershipChanged={onOwnershipChanged}
                        />
                    </div>
                )}
            </DialogContent>
        </Dialog>
    );
};

export default SharingDialog;
