import useApi from '@hooks/useApi';
import React, { ReactNode, useState } from 'react';
import useSWR from 'swr';
import { SharingMember } from '@models/User';
import Endpoints from '@services/Endpoints';
import { datadogLogs } from '@datadog/browser-logs';
import { hasPermission } from '@util/PermissionUtil';
import { defaultRoleLevels, PermissionName, PermissionObjectName } from '@models/Permission';
import SharingDialog from '@components/sharing/SharingDialog';
import Logger from '@util/Logger';
import { useLabSpaceContext } from '@contexts/LabSpaceContext';
import { PaginationResponse } from '@services/EndpointUtil';
import useAuth from '@hooks/useAuth';
import useMatchMutate from '@hooks/useMatchMutate';
import { InviteExistingUser } from '@/src/models/Invitation';

const logger = Logger.make('ProjectSharingDialog');

const ProjectSharingDialog = () => {
    const api = useApi();
    const { user } = useAuth();
    const { startsWithMutate } = useMatchMutate();

    const { projectSharingModal, updateProjectSharingModal } = useLabSpaceContext();
    const { item: project, open } = projectSharingModal;

    const [error, setError] = useState<string | ReactNode | null>(null);

    const {
        data,
        error: membersError,
        mutate,
    } = useSWR<PaginationResponse<SharingMember>>(() =>
        project ? Endpoints.lab.project.members({ projectId: project.uuid }) : null,
    );

    const { data: orgMembers, error: orgMembersError } = useSWR<PaginationResponse<SharingMember>>(
        () => (user?.organization ? Endpoints.organization.members({ organizationId: user.organization.uuid }) : null),
        { revalidateOnMount: true },
    );

    const members = data?.items;
    const availableMembers = orgMembers?.items;
    const membersCount = data?.count;
    const membersLoading = !data && !membersError && !orgMembers && !orgMembersError;

    const handleClose = () => {
        updateProjectSharingModal({ open: false, error: null });
    };

    const handleInviteExistingUsers = async (values: InviteExistingUser) => {
        if (!project) return { success: false, error: 'Project does not exist' };
        const response = { success: false, error: '' };
        try {
            const payload = { member_ids: values.member_ids, role_shortname: values.role_type };
            await api.post(Endpoints.lab.project.members({ projectId: project.uuid }), payload);
            await mutate();
            response.success = true;
        } catch (error) {
            logger.error(error);
            response.error = 'Failed to invite users';
        } finally {
            return response;
        }
    };

    const handleRemoveMember = async (member: SharingMember) => {
        updateProjectSharingModal({ error: null });
        if (!project) {
            setError('Can not remove member. No project was found.');
            return;
        }
        try {
            const updatedMembers = (members ?? [])?.filter((m) => m.uuid !== member.uuid);
            await mutate({ items: updatedMembers, count: updatedMembers.length }, false);
            await api.doDelete<void>(
                Endpoints.lab.project.member({
                    projectId: project.uuid,
                    memberId: member.uuid,
                }),
            );
        } catch (error) {
            datadogLogs.logger.error('Remove member', {}, error);
            logger.error(error);
            setError(`Unable to remove member: ${error.message ?? 'An unknown error occurred'}`);
        } finally {
            await mutate();
        }
    };

    const handleSaveMember = async (member: SharingMember) => {
        setError(null);
        if (!project) {
            setError('Can not save member. No project was found.');
            return;
        }
        try {
            await api.put<void>(
                Endpoints.lab.project.member({
                    projectId: project.uuid,
                    memberId: member.uuid,
                }),
                member,
            );
            const index = members?.findIndex((m) => m.uuid === member.uuid) ?? -1;
            if (index >= 0 && members) {
                logger.debug(`updating members by updating member at index ${index} to `, member);
                const updatedMembers = [...members];
                updatedMembers[index] = member;
                await mutate({ items: updatedMembers, count: updatedMembers.length }, false);
            }
        } catch (error) {
            logger.error(error);
            setError(`Unable to save member: ${error.message ?? 'An unknown error occurred'}`);
        } finally {
            await mutate();
        }
    };

    if (!project) {
        return null;
    }

    const canRemoveMembers = hasPermission(project, { requires: PermissionName.remove_project_users });
    const canEditRoles = hasPermission(project, { requires: PermissionName.invite_project_users });
    const canTransferOwnership = canEditRoles && project.owner?.uuid === user?.uuid;
    const handleOwnershipChanged = async () => {
        await Promise.all([startsWithMutate(Endpoints.lab.projects())]);
    };

    return (
        <SharingDialog
            item={project}
            itemType={PermissionObjectName.project}
            title={'Project Sharing'}
            open={open}
            onClose={handleClose}
            roleLevels={defaultRoleLevels}
            members={members}
            membersCount={membersCount}
            membersLoading={membersLoading}
            removeMember={handleRemoveMember}
            canRemoveMembers={canRemoveMembers}
            canEditRoles={canEditRoles}
            error={error}
            saveMember={handleSaveMember}
            canTransferOwnership={canTransferOwnership}
            onOwnershipChanged={handleOwnershipChanged}
            availableMembers={availableMembers}
            handleInviteExistingUsers={handleInviteExistingUsers}
        />
    );
};

export default ProjectSharingDialog;
