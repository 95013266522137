import ConfirmModal from '@components/ConfirmModal';
import React, { useState } from 'react';
import { useLabSpaceContext } from '@contexts/LabSpaceContext';
import useProjectApi from '@hooks/useProjectApi';
import Logger from '@util/Logger';

const logger = Logger.make('ProjectArchiveConfirmModal');

const ProjectArchiveConfirmModal = () => {
    const projectApi = useProjectApi();
    const labContext = useLabSpaceContext();
    const { updateProjectArchiveModal: updateModal, projectArchiveModal, updateProjectCreateModal } = labContext;
    const { item: project, open } = projectArchiveModal;
    const [archiving, setArchiving] = useState(false);
    const handleClose = () => {
        updateModal({ open: false, item: null, error: null });
    };

    const handleArchive = async () => {
        if (!project) {
            return;
        }
        try {
            setArchiving(true);
            await projectApi.archiveProject({ project });
            handleClose();
            updateProjectCreateModal({ open: false, item: null });
        } catch (error) {
            logger.error(error);
        } finally {
            setArchiving(false);
        }
    };

    if (!project) {
        return null;
    }

    return (
        <ConfirmModal
            key={`project_${project?.uuid ?? 'none'}`}
            open={open}
            onConfirm={handleArchive}
            onCancel={() => handleClose()}
            title="Archive project & its experiments?"
            message={`Archiving "${project.name}" will also archive the ${project.experiments_count} ${
                project.experiments_count === 1 ? 'experiment' : 'experiments'
            } inside this project. Are you sure you want to continue?`}
            confirmText="Yes, Archive all"
            cancelText="Cancel"
            confirmLoading={archiving}
        />
    );
};

export default ProjectArchiveConfirmModal;
