import { Dialog, DialogActions, DialogContent } from '@mui/material';
import Button from '@components/Button';
import { ReactNode } from 'react';
import useBreakpoints from '@hooks/useBreakpoints';
import DialogCloseButton from '@components/DialogCloseButton';
import PlutoDialogTitle from '@components/PlutoDialogTitle';
import { Alert } from '@mui/material';

export type Props = {
    open?: boolean;
    title?: string;
    message?: string | ReactNode;
    confirmText?: string;
    cancelText?: string;
    onConfirm: () => void;
    onCancel: () => void;
    confirmLoading?: boolean;
    icon?: ReactNode;
    error?: ReactNode;
};
const ConfirmModal = ({
    open = false,
    message,
    title,
    confirmText = 'Confirm',
    cancelText = 'Cancel',
    onCancel,
    onConfirm,
    confirmLoading = false,
    icon,
    error,
}: Props) => {
    const { isSmAndDown } = useBreakpoints();
    const handleConfirm = () => {
        onConfirm();
    };

    return (
        <Dialog open={open} maxWidth="sm">
            <DialogCloseButton onClose={() => onCancel()} />
            {title && <PlutoDialogTitle iconStart={icon} iconEnd={icon} title={title} />}
            {message && (
                <DialogContent className="mb-4 space-y-4">
                    {error && <Alert severity="error">{error}</Alert>}
                    <div className="text-base">{message}</div>
                </DialogContent>
            )}
            <DialogActions>
                <Button variant={isSmAndDown ? 'outlined' : 'text'} onClick={onCancel} fullWidth={isSmAndDown}>
                    {cancelText}
                </Button>
                <Button
                    color="primary"
                    onClick={handleConfirm}
                    variant="contained"
                    loading={confirmLoading}
                    disabled={confirmLoading}
                    fullWidth={isSmAndDown}
                >
                    {confirmText}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
export default ConfirmModal;
