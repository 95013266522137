import React, { useState } from 'react';
import useAuth from '@hooks/useAuth';
import Endpoints from '@services/Endpoints';
import { Organization } from '@models/Organization';
import useSWR from 'swr';
import { ChevronDownIcon } from '@heroicons/react/solid';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useRouter } from 'next/router';

const Banner = () => {
    const { user } = useAuth();
    const router = useRouter();
    const { data: organization } = useSWR<Organization>(() =>
        user?.organization ? Endpoints.organization.base({ organizationId: user?.organization?.uuid }) : null,
    );
    const { data: allOrganizations } = useSWR<Organization[]>(() => (user ? Endpoints.organizations() : null));

    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

    const open = Boolean(menuAnchorEl);
    const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        setMenuAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setMenuAnchorEl(null);
    };

    const handleSelectOrg = async (org: Organization) => {
        await router.push({ pathname: '/labspace', query: { organization: org.org_slug } });
        location.reload();
    };

    if (!organization) return <></>;

    // Compute header styles directly based on organization data
    const headerStyles = {
        backgroundColor: organization.header_background_color ?? '#333',
        backgroundImage: organization.header_background_image ? `url(${organization.header_background_image})` : 'none',
        backgroundPosition: 'top left',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
    };

    const logoImage = organization.header_logo ? (
        <img style={{ height: '20px' }} src={organization.header_logo} />
    ) : null;

    return (
        <div
            className={`ml-0 px-3 py-3 text-xs text-white md:ml-[60px] ${
                organization.header_background_color ? '' : 'bg-gray-dark'
            }`}
            style={headerStyles}
        >
            <div className="flex">
                <div className="flex-grow">
                    <div className="flex">
                        <div className="pr-2">
                            {!!organization.header_logo && (
                                <>
                                    {!!organization.logo_link ? (
                                        <a
                                            href={organization.logo_link ?? '/'}
                                            target="_blank"
                                            className="relative cursor-pointer"
                                            rel="noreferrer"
                                        >
                                            {logoImage}
                                        </a>
                                    ) : (
                                        logoImage
                                    )}
                                </>
                            )}
                        </div>
                        {!!allOrganizations ? (
                            <div>
                                <div className="flex">
                                    <div className="pt-[2px]">{organization.name}</div>
                                    {allOrganizations.length > 1 ? (
                                        <>
                                            <div
                                                className="ml-4 mt-[1px] flex cursor-pointer items-center"
                                                onClick={handleMenuClick}
                                            >
                                                <ChevronDownIcon
                                                    width="16"
                                                    className="mr-1 mt-[1px] text-xs text-white"
                                                />{' '}
                                                Switch organization
                                            </div>
                                            <Menu
                                                autoFocus={false}
                                                anchorEl={menuAnchorEl}
                                                open={open}
                                                onClose={handleMenuClose}
                                                className="mt-6"
                                                sx={{
                                                    '& .MuiMenu-paper': {
                                                        backgroundColor: '#444444',
                                                        border: 'none',
                                                    },
                                                }}
                                                anchorOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'left',
                                                }}
                                            >
                                                {allOrganizations.map((org) => (
                                                    <MenuItem
                                                        key={org.uuid}
                                                        onClick={() => handleSelectOrg(org)}
                                                        sx={{
                                                            color: 'rgba(255, 255, 255, 0.7)',
                                                            '&:hover': {
                                                                color: 'white',
                                                            },
                                                        }}
                                                    >
                                                        {org.name}
                                                    </MenuItem>
                                                ))}
                                            </Menu>
                                        </>
                                    ) : null}
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>

                <div className="w-1/3 pr-[2px] pt-[2px] text-right">{organization.header_text}</div>
            </div>
        </div>
    );
};

export default Banner;
