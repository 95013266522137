import Link from 'next/link';
import UserAvatar from '@components/auth/UserAvatar';
import { useRef, useState } from 'react';
import cn from 'classnames';
import { useRouter } from 'next/router';
import { Menu, MenuItem, Tooltip } from '@mui/material';
import useAuth from '@hooks/useAuth';
import {
    BookOpenIcon,
    ChartBarIcon,
    ChartPieIcon,
    ClipboardIcon,
    CogIcon,
    FolderIcon,
    MailIcon,
    SearchIcon,
    TemplateIcon,
} from '@heroicons/react/outline';
import { HomeIcon } from '@components/icons/custom/HomeIcon';
import { SolarSystemIcon } from '@components/icons/custom/SolarSystemIcon';
import { UserIcon } from '@components/icons/custom/UserIcon';
import { CashIcon } from '@heroicons/react/outline';
import { HelpIcon } from '@components/icons/custom/HelpIcon';
import { LogOutIcon } from '@components/icons/custom/LogOutIcon';
import { UsersIcon } from '@components/icons/custom/UsersIcon';
import usePendingInvites from '@hooks/usePendingInvites';
import { useLabSpaceContext } from '@contexts/LabSpaceContext';
import { SunIcon } from '@heroicons/react/outline';
import { DatabaseIcon } from '@heroicons/react/outline';
import CrosshairsIcon from '@components/icons/custom/CrosshairsIcon';
import { LightBulbIcon } from '@heroicons/react/outline';
import CommentsIcon from '@components/icons/custom/CommentsIcon';
import { CursorClickIcon } from '@heroicons/react/outline';
import SupportDialog from '@components/support/SupportDialog';
import useDisableHubSpotChat from '@components/support/useDisableHubspotChat';
import useOrganizationPermissions from '@hooks/useOrganizationPermissions';
import { PermissionName } from '@/src/models/Permission';
import { SupportIcon } from '@heroicons/react/outline';

type NavItemProps = {
    href: string;
    icon: React.ReactNode;
    label: string;
    toolText?: string;
};

type DropDownLinkProps = {
    href: string;
    icon: React.ReactNode;
    label: React.ReactNode;
    onClick: () => void;
    target?: string;
};

const NavItem = ({ href, icon, label, toolText }: NavItemProps) => {
    const router = useRouter();
    const isCurrentPath = router.pathname?.startsWith(href) ?? false;
    return (
        <Tooltip arrow title={toolText ?? label} placement="right">
            <div className="flex flex-col items-center justify-center space-y-1 md:space-y-2">
                <Link href={href} passHref legacyBehavior>
                    <div
                        data-cy="nav-item"
                        data-active={isCurrentPath ? 'yes' : undefined}
                        className={cn(
                            'cursor-pointer text-white no-underline transition-opacity hover:text-white hover:no-underline hover:opacity-100',
                            {
                                'opacity-70': !isCurrentPath,
                                'opacity-100': isCurrentPath,
                            },
                        )}
                    >
                        {icon}
                    </div>
                </Link>
            </div>
        </Tooltip>
    );
};

const DropdownLink = ({ href, icon, label, onClick, target }: DropDownLinkProps) => {
    const router = useRouter();
    const isCurrentPath = router.pathname?.startsWith(href) ?? false;

    return (
        <Link
            href={href}
            passHref
            target={target}
            style={{
                color: isCurrentPath ? 'white' : 'rgba(255, 255, 255, 0.7)',
                display: 'flex',
                alignItems: 'center',
            }}
        >
            <MenuItem
                onClick={onClick}
                selected={isCurrentPath}
                sx={{
                    color: 'rgba(255, 255, 255, 0.7)',
                    '&:hover': {
                        color: 'white',
                    },
                }}
            >
                <span style={{ display: 'flex', alignItems: 'center', marginRight: '8px' }}>{icon}</span>
                {label}
            </MenuItem>
        </Link>
    );
};

const TeamLink = () => {
    const { user } = useAuth();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <span
                className={cn(
                    'transition-text-opacity flex cursor-pointer flex-col items-center justify-center space-y-1 text-white no-underline hover:text-opacity-100 hover:no-underline md:space-y-2',
                    {
                        'text-opacity-100': !!anchorEl,
                        'text-opacity-70': !anchorEl,
                    },
                )}
                onClick={handleClick}
            >
                <Tooltip title="Team" arrow placement="right">
                    <div className="flex w-full justify-center">
                        <UsersIcon
                            className={cn('w-6 hover:text-opacity-100', {
                                'text-opacity-100': !!anchorEl,
                                'text-opacity-70': !anchorEl,
                            })}
                        />
                    </div>
                </Tooltip>
            </span>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={!!anchorEl}
                onClose={handleClose}
                className="ml-2"
                sx={{
                    '& .MuiMenu-paper': {
                        backgroundColor: '#444444',
                        border: 'none',
                    },
                }}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <DropdownLink href="/team" icon={<UsersIcon width={22} />} label="Manage team" onClick={handleClose} />
                <DropdownLink
                    href="/organization"
                    icon={<CogIcon width={22} />}
                    label="Organization settings"
                    onClick={handleClose}
                />
                {((user && user.organization_role) ?? '').toLowerCase() === 'owner' && (
                    <DropdownLink
                        href="/organization/subscription"
                        icon={<CashIcon width={22} />}
                        label="Subscription"
                        onClick={handleClose}
                    />
                )}
                <DropdownLink
                    href="/metrics"
                    icon={<ChartPieIcon width={24} />}
                    label="Metrics"
                    onClick={handleClose}
                />
            </Menu>
        </>
    );
};

const DataLink = () => {
    const { user } = useAuth();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <span
                className={cn(
                    'transition-text-opacity flex cursor-pointer flex-col items-center justify-center space-y-1 text-white no-underline hover:text-opacity-100 hover:no-underline md:space-y-2',
                    {
                        'text-opacity-100': !!anchorEl,
                        'text-opacity-70': !anchorEl,
                    },
                )}
                onClick={handleClick}
            >
                <Tooltip title="Data" arrow placement="right">
                    <div className="flex w-full justify-center">
                        <DatabaseIcon
                            className={cn('w-6 hover:text-opacity-100', {
                                'text-opacity-100': !!anchorEl,
                                'text-opacity-70': !anchorEl,
                            })}
                        />
                    </div>
                </Tooltip>
            </span>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={!!anchorEl}
                onClose={handleClose}
                className="ml-2"
                sx={{
                    '& .MuiMenu-paper': {
                        backgroundColor: '#444444',
                        border: 'none',
                    },
                }}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                {!!user && !!user.organization?.user_permissions?.includes(PermissionName.edit_organization) && (
                    <DropdownLink
                        href="/data"
                        icon={<DatabaseIcon width={22} />}
                        label="Data management"
                        onClick={handleClose}
                    />
                )}
                <DropdownLink
                    href="/biomarker_lists"
                    icon={<SunIcon width={22} />}
                    label="Biomarkers"
                    onClick={handleClose}
                />
                <DropdownLink
                    href="/target_lists"
                    icon={<CrosshairsIcon width={22} />}
                    label="Targets"
                    onClick={handleClose}
                />
            </Menu>
        </>
    );
};

const SearchLink = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const iconRef = useRef(null);
    const { pathname } = useRouter();

    const handleClick = (event) => {
        setAnchorEl(iconRef.current ?? event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const isSearchRoute = ['/experiments', '/plots', '/projects'].includes(pathname);

    return (
        <>
            <span
                className={cn(
                    'transition-text-opacity flex cursor-pointer flex-col items-center justify-center space-y-1 text-white no-underline hover:text-opacity-100 hover:no-underline md:space-y-2',
                    {
                        'text-opacity-100': !!anchorEl || isSearchRoute,
                        'text-opacity-70': !anchorEl && !isSearchRoute,
                    },
                )}
                onClick={handleClick}
            >
                <Tooltip title="Search" arrow placement="right">
                    <div className="flex w-full justify-center">
                        <SearchIcon
                            className={cn('w-6 hover:text-opacity-100', {
                                'text-opacity-100': !!anchorEl || isSearchRoute,
                                'text-opacity-70': !anchorEl && !isSearchRoute,
                            })}
                            ref={iconRef}
                        />
                    </div>
                </Tooltip>
            </span>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={!!anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                className="ml-6"
                sx={{
                    '& .MuiMenu-paper': {
                        backgroundColor: '#444444',
                        border: 'none',
                    },
                }}
            >
                <DropdownLink
                    href="/experiments"
                    icon={<ClipboardIcon width={22} />}
                    label="Experiments"
                    onClick={handleClose}
                />
                <DropdownLink href="/plots" icon={<ChartBarIcon width={22} />} label="Plots" onClick={handleClose} />
                <DropdownLink
                    href="/projects"
                    icon={<FolderIcon width={22} />}
                    label="Projects"
                    onClick={handleClose}
                />
                <DropdownLink
                    href="/explore"
                    icon={<SolarSystemIcon width={22} />}
                    label="Published data"
                    onClick={handleClose}
                />
                <DropdownLink
                    href="/literature-datasets"
                    icon={<BookOpenIcon width={22} />}
                    label="Literature"
                    onClick={handleClose}
                />
            </Menu>
        </>
    );
};

const AccountLink = () => {
    const { logout } = useAuth();
    const [anchorEl, setAnchorEl] = useState(null);
    const { setPendingInvitesModalOpen } = useLabSpaceContext();
    const { data: invites, mutate: mutateInvites } = usePendingInvites();
    const { replace } = useRouter();
    const numPendingInvites = (invites ?? []).filter((invite) => invite.is_active).length;

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        mutateInvites((current) => current, { revalidate: true });
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = async () => {
        handleClose();
        await Promise.all([replace('/'), logout()]);
    };

    return (
        <>
            <span
                className="transition-text-opacity relative flex cursor-pointer flex-col items-center justify-center space-y-1 text-white text-opacity-70 no-underline hover:text-opacity-100 hover:no-underline md:space-y-2"
                onClick={handleClick}
                data-cy="account-item"
            >
                <Tooltip title="Account" arrow placement="right">
                    <div>
                        <UserAvatar size="xs" widthClass="w-8" heightClass="h-8" diameter="100%" disableTooltip />
                    </div>
                </Tooltip>
                {numPendingInvites > 0 && (
                    <span className="absolute -right-2 -top-4 flex items-center justify-center rounded-full bg-secondary px-1.5 py-0.5 font-semibold text-white opacity-100">
                        <span>{numPendingInvites}</span>
                    </span>
                )}
            </span>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={!!anchorEl}
                onClose={handleClose}
                className="ml-4"
                sx={{
                    '& .MuiMenu-paper': {
                        backgroundColor: '#444444',
                        border: 'none',
                    },
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                data-cy="account-menu"
            >
                <DropdownLink
                    href="#"
                    icon={<MailIcon width={17} height={17} />}
                    label={
                        <span className="relative flex items-center space-x-2">
                            <span>Invites</span>
                            {numPendingInvites > 0 && (
                                <span className="flex items-center justify-center">
                                    <span className="flex items-center justify-center rounded-full bg-secondary px-[6px] py-[1px] text-sm font-semibold text-white">
                                        <span>{numPendingInvites.toLocaleString()}</span>
                                    </span>
                                </span>
                            )}
                        </span>
                    }
                    onClick={() => {
                        setPendingInvitesModalOpen(true);
                        handleClose();
                    }}
                />
                <DropdownLink href="/account" icon={<UserIcon />} label="Account" onClick={handleClose} />
                <MenuItem onClick={handleLogout} className="text-white">
                    <span className="mr-2">
                        <LogOutIcon width={17} height={17} />
                    </span>
                    Log out
                </MenuItem>
            </Menu>
        </>
    );
};

const OrganizationUserLinks = () => {
    const { user } = useAuth();

    return (
        <>
            <NavItem href={'/labspace'} icon={<HomeIcon />} label="Lab Space" />
            {user?.organization ? (
                <>
                    <SearchLink />
                    <DataLink />
                    <NavItem href={'/apps'} icon={<TemplateIcon width={24} />} label="Apps" />
                    <TeamLink />
                </>
            ) : null}
        </>
    );
};

const HelpLink = (features) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [openSupportDialog, setOpenSupportDialog] = useState(false);
    const LiveChatEnabled = features?.features?.experiment_features?.livechat_enabled;

    useDisableHubSpotChat(!LiveChatEnabled);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLiveChatClick = () => {
        handleClose(); // Close the menu
        (window as any).HubSpotConversations?.widget?.open();
    };

    const handleEmailSupportClick = () => {
        handleClose(); // Close the menu
        setOpenSupportDialog(true); // Open the support dialog
    };

    return (
        <div className="hidden md:block">
            <span
                className="transition-text-opacity mb-4 flex cursor-pointer flex-col items-center justify-center space-y-1 text-white no-underline hover:text-opacity-100 hover:no-underline md:space-y-2"
                onClick={handleClick}
            >
                <Tooltip title="Help" arrow placement="right">
                    <div>
                        <HelpIcon width="34" />
                    </div>
                </Tooltip>
            </span>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={!!anchorEl}
                onClose={handleClose}
                className="ml-10"
                sx={{
                    '& .MuiMenu-paper': {
                        backgroundColor: '#444444',
                        border: 'none',
                    },
                }}
            >
                <DropdownLink
                    href="/support/knowledge-base"
                    icon={<LightBulbIcon width="22" />}
                    label="Knowledge Base"
                    onClick={handleClose}
                />
                <DropdownLink
                    href="/support/portal"
                    icon={<SupportIcon width="22" />}
                    label="Support portal"
                    onClick={handleClose}
                />
                <DropdownLink
                    href="/get-started/industry"
                    icon={<CursorClickIcon width="22" />}
                    label="Getting started guide"
                    onClick={handleClose}
                />
                {LiveChatEnabled && (
                    <DropdownLink
                        href="#"
                        icon={<CommentsIcon className="text-white" width="22" />}
                        label="Live chat"
                        onClick={handleLiveChatClick}
                    />
                )}
                <DropdownLink
                    href="#"
                    icon={<MailIcon width="22" />}
                    label="Email support"
                    onClick={handleEmailSupportClick}
                />
            </Menu>
            <SupportDialog open={openSupportDialog} onCancel={() => setOpenSupportDialog(false)} />
        </div>
    );
};

type Props = { className?: string };
const DashboardNavigation = ({ className }: Props) => {
    const { isLoggedIn } = useAuth();
    const { features } = useOrganizationPermissions();

    return (
        <div
            className={cn(
                'flex h-16 w-full flex-row bg-gray-dark px-4 pb-2 pt-2 sm:h-16 md:flex-col md:px-0',
                'z-50  md:h-screen',
                'fixed bottom-0 left-0 overflow-auto md:top-0 md:w-[60px]',
                className,
            )}
        >
            <Link
                href="https://pluto.bio"
                passHref
                className="pluto-logo-nav-container mb-6 hidden cursor-pointer md:inline-block"
            >
                <div className="pluto-logo"></div>
            </Link>
            <div
                className="relative flex h-full w-full flex-grow flex-row justify-between space-x-2 text-xs md:min-h-[400px] md:flex-col md:justify-start md:space-x-0 md:space-y-8"
                data-cy="dashboard-nav"
            >
                <OrganizationUserLinks />
                {isLoggedIn && (
                    <div className="flex flex-col items-center justify-center md:absolute md:bottom-5 md:w-full">
                        <HelpLink features={features} />
                        <AccountLink />
                    </div>
                )}
            </div>
        </div>
    );
};

export default DashboardNavigation;
