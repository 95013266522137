import { createContext, ReactNode, useContext, useState } from 'react';
import { Project } from '@models/Project';
import Experiment from '@models/Experiment';
import usePendingInvites from '@hooks/usePendingInvites';
import PendingInvitesDialog from '@components/PendingInvitesDialog';

type ModalState<T> = { open: boolean; item?: T | null | undefined; error?: string | null };
type UpdateModalState<T> = (values: Partial<ModalState<T>>) => void;

type ContextType = {
    projectArchiveModal: ModalState<Project>;
    updateProjectArchiveModal: UpdateModalState<Project>;

    // TODO: remove project create from this context. it should be localized
    projectCreateModal: ModalState<never>;
    updateProjectCreateModal: UpdateModalState<never>;

    projectSharingModal: ModalState<Project>;
    updateProjectSharingModal: UpdateModalState<Project>;

    experimentSharingModal: ModalState<Experiment>;
    updateExperimentSharingModal: UpdateModalState<Experiment>;

    pendingInvitesModalOpen: boolean;
    setPendingInvitesModalOpen: (open: boolean) => void;
};

const LabSpaceContext = createContext<ContextType | null>(null);
LabSpaceContext.displayName = 'LabSpaceContext';

export const useLabSpaceContext = () => {
    const context = useContext(LabSpaceContext);
    if (!context) {
        throw new Error('Attempted to use LabSpace context before it was defined.');
    }
    return context;
};

type Props = { children?: ReactNode };
export const LabSpaceContextProvider = ({ children }: Props) => {
    const [projectArchiveModal, setProjectArchiveModal] = useState<ModalState<Project>>({ open: false });
    const [projectCreateModal, setProjectCreateModal] = useState<ModalState<never>>({ open: false });

    const [projectSharingModal, setProjectSharingModal] = useState<ModalState<Project>>({ open: false });
    const [experimentSharingModal, setExperimentSharingModal] = useState<ModalState<Experiment>>({ open: false });

    const [pendingInvitesModalOpen, setPendingInvitesModalOpen] = useState(false);
    usePendingInvites();

    return (
        <LabSpaceContext.Provider
            value={{
                projectArchiveModal,
                updateProjectArchiveModal: (values) => setProjectArchiveModal((current) => ({ ...current, ...values })),

                projectCreateModal,
                updateProjectCreateModal: (values) => setProjectCreateModal((current) => ({ ...current, ...values })),

                projectSharingModal,
                updateProjectSharingModal: (values) => setProjectSharingModal((current) => ({ ...current, ...values })),

                experimentSharingModal,
                updateExperimentSharingModal: (values) =>
                    setExperimentSharingModal((current) => ({ ...current, ...values })),

                pendingInvitesModalOpen,
                setPendingInvitesModalOpen,
            }}
        >
            {children}
            <PendingInvitesDialog />
        </LabSpaceContext.Provider>
    );
};
